import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from 'gatsby'
import media from "../../../styles/breakPoints"
import { useIntl } from 'gatsby-plugin-intl';

import Layout from "../../../components/Layout";
import SEO from "../../../components/seo"

import Section from "../../../objects/Section"
import ProductSection from "../../../components/ProductSection"

const WrapSections = styled(Section)`
   background-color: ${props => props.theme.color.grayLight};
   flex-direction: column;
   padding: 0;
   align-items: flex-start;

   ${media.greaterThan("md")`
      padding: 20px 0;
   `}

   ${media.greaterThan("lg")`
      padding: 40px 0;
   `}

`

const ChavesEletricas = () => {

   const intl = useIntl();

   const content = useStaticQuery(
      graphql`
         query {
            imgCE_Row1: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas/row1" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCE_Row2: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas/row2" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCE_Row3: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas/row3" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCE_Row4: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas/row4" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCE_Row5: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas/row5" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCE_Row6: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas/row6" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCE_Row7: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas/row7" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCE_Row8: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas/row8" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCE_Row9: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas/row9" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCE_Row10: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas/row10" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCE_Row11: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas/row11" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCE_Row12: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas/row12" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCE_Row13: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas/row13" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            pdfCE_Row1: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas-row1" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCE_Row2: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas-row2" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCE_Row3: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas-row3" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCE_Row4: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas-row4" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCE_Row5: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas-row5" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCE_Row6: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas-row6" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCE_Row7: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas-row7" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCE_Row8: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas-row8" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCE_Row9: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas-row9" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCE_Row10: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas-row10" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCE_Row11: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas-row11" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCE_Row12: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas-row12" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCE_Row13: allFile (
               filter: 
                  {  relativeDirectory: { eq: "chaves-eletricas-row13" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
         }      
      `
   )
   
   return (
      <Layout>
         <SEO 
            lang={intl.locale}
            title={intl.formatMessage({ id: "navdropdown.item1" })}
            keywords={[ `indústria metalúrgica em São Paulo`, `chave elétrica de emergência`, `chave elétrica indicadora`, `chave elétrica fim de curso`, `chave elétrica combinadora`, `chave elétrica limite de potência`, `chave elétrica rotativa` ]}
         />
         <WrapSections>
            <ProductSection
               tagAs="h1"
               productline="chaveseletricaspage"
               row="contentCE_Row1"
               section={intl.formatMessage({ id: "chaveseletricaspage.contentCE_Row1.section" })}
               element="glide-chaves-eletricas-row1" 
               children={content.imgCE_Row1.edges}
               catalog={content.pdfCE_Row1.edges}
               totalCount={content.pdfCE_Row1.totalCount}
            />
            <ProductSection
               productline="chaveseletricaspage"
               row="contentCE_Row2"
               section={intl.formatMessage({ id: "chaveseletricaspage.contentCE_Row2.section" })}
               element="glide-chaves-eletricas-row2" 
               children={content.imgCE_Row2.edges}
               catalog={content.pdfCE_Row2.edges}
               totalCount={content.pdfCE_Row2.totalCount}
            />
            <ProductSection
               productline="chaveseletricaspage"
               row="contentCE_Row3"
               section={intl.formatMessage({ id: "chaveseletricaspage.contentCE_Row3.section" })}
               element="glide-chaves-eletricas-row3" 
               children={content.imgCE_Row3.edges}
               catalog={content.pdfCE_Row3.edges}
               totalCount={content.pdfCE_Row3.totalCount}
            />
            <ProductSection
               productline="chaveseletricaspage"
               row="contentCE_Row4"
               section={intl.formatMessage({ id: "chaveseletricaspage.contentCE_Row4.section" })}
               element="glide-chaves-eletricas-row4" 
               children={content.imgCE_Row4.edges}
               catalog={content.pdfCE_Row4.edges}
               totalCount={content.pdfCE_Row4.totalCount}
            />
            <ProductSection
               productline="chaveseletricaspage"
               row="contentCE_Row5"
               section={intl.formatMessage({ id: "chaveseletricaspage.contentCE_Row5.section" })}
               element="glide-chaves-eletricas-row5" 
               children={content.imgCE_Row5.edges}
               catalog={content.pdfCE_Row5.edges}
               totalCount={content.pdfCE_Row5.totalCount}
            />
            <ProductSection
               productline="chaveseletricaspage"
               row="contentCE_Row6"
               section={intl.formatMessage({ id: "chaveseletricaspage.contentCE_Row6.section" })}
               element="glide-chaves-eletricas-row6" 
               children={content.imgCE_Row6.edges}
               catalog={content.pdfCE_Row6.edges}
               totalCount={content.pdfCE_Row6.totalCount}
            />
            <ProductSection
               productline="chaveseletricaspage"
               row="contentCE_Row7"
               section={intl.formatMessage({ id: "chaveseletricaspage.contentCE_Row7.section" })}
               element="glide-chaves-eletricas-row7" 
               children={content.imgCE_Row7.edges}
               catalog={content.pdfCE_Row7.edges}
               totalCount={content.pdfCE_Row7.totalCount}
            />
            <ProductSection
               productline="chaveseletricaspage"
               row="contentCE_Row8"
               section={intl.formatMessage({ id: "chaveseletricaspage.contentCE_Row8.section" })}
               element="glide-chaves-eletricas-row8" 
               children={content.imgCE_Row8.edges}
               catalog={content.pdfCE_Row8.edges}
               totalCount={content.pdfCE_Row8.totalCount}
            />
            <ProductSection
               productline="chaveseletricaspage"
               row="contentCE_Row9"
               section={intl.formatMessage({ id: "chaveseletricaspage.contentCE_Row9.section" })}
               element="glide-chaves-eletricas-row9" 
               children={content.imgCE_Row9.edges}
               catalog={content.pdfCE_Row9.edges}
               totalCount={content.pdfCE_Row9.totalCount}
            />
            <ProductSection
               productline="chaveseletricaspage"
               row="contentCE_Row10"
               section={intl.formatMessage({ id: "chaveseletricaspage.contentCE_Row10.section" })}
               element="glide-chaves-eletricas-row10" 
               children={content.imgCE_Row10.edges}
               catalog={content.pdfCE_Row10.edges}
               totalCount={content.pdfCE_Row10.totalCount}
            />
            <ProductSection
               productline="chaveseletricaspage"
               row="contentCE_Row11"
               section={intl.formatMessage({ id: "chaveseletricaspage.contentCE_Row11.section" })}
               element="glide-chaves-eletricas-row11" 
               children={content.imgCE_Row11.edges}
               catalog={content.pdfCE_Row11.edges}
               totalCount={content.pdfCE_Row11.totalCount}
            />
            <ProductSection
               productline="chaveseletricaspage"
               row="contentCE_Row12"
               section={intl.formatMessage({ id: "chaveseletricaspage.contentCE_Row12.section" })}
               element="glide-chaves-eletricas-row12" 
               children={content.imgCE_Row12.edges}
               catalog={content.pdfCE_Row12.edges}
               totalCount={content.pdfCE_Row12.totalCount}
            />
            <ProductSection
               productline="chaveseletricaspage"
               row="contentCE_Row13"
               section={intl.formatMessage({ id: "chaveseletricaspage.contentCE_Row13.section" })}
               element="glide-chaves-eletricas-row13" 
               children={content.imgCE_Row13.edges}
               catalog={content.pdfCE_Row13.edges}
               totalCount={content.pdfCE_Row13.totalCount}
            />
         </WrapSections>
      </Layout>
   )
}

export default ChavesEletricas;